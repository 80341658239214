import axios from 'axios';

import {refresh, getUserInfo} from './user';
import { notification } from '@ctm/ui/Notification';
import dayjs from 'dayjs';

const {interceptors} = axios;
let key;

interceptors.request.use((config) => {
  if (config.apiVersion) {
    config.headers['X-api-version'] = config.apiVersion;
  }

  let activeConsultantDebtor = localStorage.getItem('activeDebtor');

  if(activeConsultantDebtor) {
    config.headers['X-Api-Debtor'] = activeConsultantDebtor;
  }

  if (config.params) {
    const keys = ['q', 'query', 'departmentCode', 'term'];
    const reg = /[^A-Za-z '@,\-.0-9]+/g;

    Object.keys(config.params).forEach(key => {
      if (keys.includes(key))
        config.params[key] = config.params[key].replace(reg, '');
    })
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

interceptors.response.use((response) => {
  notification.cancel(key);
  return response
}, (error) => {
  if (error.constructor.name === 'Cancel' || error.config.silentError) return Promise.reject(error);

  if (error.config.preventGlobalError) return Promise.reject(error);

  if (!error.response || (error.response && [500, 400].includes(error.response.status))) {
    return new Promise((resolve, reject) => {
      key = notification.generateKey();
      notification.open({
        key,
        title: 'Error!',
        text: (error.response && error.response.data && error.response.data.Message) ? error.response.data.Message : `Sorry, looks like there has been an error`,
        isDark: true,
        actions: {
          'Retry': () => {
            window._hack = 'Itinerary';
            notification.complete(key);
            axios.request(error.config).then(res => {
              resolve(res);
            });
          },
          'Cancel': () => {
            notification.cancel(key, 'cancel please');
            reject();
          }
        }
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        key = null;
      });
    });
  }

  if (error.response && error.response.status === 401 && !error.config.retry) {
    const { refresh_token } = JSON.parse(sessionStorage.getItem('oauth'));

    if (refresh_token) {
      return refresh(refresh_token).then((response) => {
        const auth = response.data;
        auth.expires_at = dayjs().add(auth.expires_in, 'second').format('x');
        sessionStorage.setItem('oauth', JSON.stringify(auth));

        const { access_token } = JSON.parse(sessionStorage.getItem('oauth')) || {};
        error.config.headers['Authorization'] = `Bearer ${access_token}`;

        error.config.retry = true;

        return getUserInfo(response.data).then((response) => {
          const {data} = response;
          sessionStorage.setItem('CURRENT_USER_userInfo', JSON.stringify(data));
          sessionStorage.setItem('IsFromAuth0', data.IsFromAuth0);

          return axios.request(error.config);
        })
      })
    }
  }

  return Promise.reject(error);
});

export default interceptors;
