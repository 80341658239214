import { notification } from '@ctm/ui/Notification';
import { modal as modalService } from '@ctm/ui/Modal';

/** Edge: adding support for ESM Dynamic import **/
import dynamicImportPolyfill from 'dynamic-import-polyfill';
dynamicImportPolyfill.initialize({ modulePath: '/' });


import './http/interceptors';
import './public/root.css';
import './public/flatpickr.css';

import HotelReasonModal from './components/HotelReasonModal/HotelReasonModal.svelte';
import HotelInfoModal from './components/HotelInfoModal/HotelInfoModal.svelte';

import {
  apiConfig,
  getItineraryId,
  setItineraryId,
  removeItineraryId,
  userInfo,
  userDebtor,
  manageBookingsItinerary,
  time,
  track,
  trackingEvents,
  trackUser,
  trackLogin,
  trackSearch,
  trackingInit,
  setTrackingIdentity,
  settings,
  logout
} from './store/index';

window.svelteStore = {
  apiConfig,
  getItineraryId,
  setItineraryId,
  removeItineraryId,
  userInfo,
  userDebtor,
  manageBookingsItinerary,
  time,
  track,
  trackingEvents,
  trackUser,
  trackLogin,
  trackSearch,
  trackingInit,
  setTrackingIdentity,
  settings,
  logout
};

window.svelteUI = {
  modalService,
  HotelReasonModal,
  HotelInfoModal,
  notification
}


const { resourceBaseUrl, resourceBaseUrlUIPath } = window;

window.loadSvelteComponent = async function (componentName, element, props) {
  if (window[componentName]) {
    try {
      window[componentName].$destroy();
    } catch(err) {
      console.warn(err);
    }
  }

  let module = await __import__(`${resourceBaseUrl}${resourceBaseUrlUIPath}/${componentName}.js`);

  const component = new module.default({
    target: element,
    props
  });

  window[componentName] = component;

  return component;
}
