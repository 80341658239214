<script>
  import Modal from '@ctm/ui/Modal';
  import TextInput from '@ctm/ui/TextInput';
  import Button, { options as buttonOptions } from '@ctm/ui/Button';
  import Dropdown from '@ctm/ui/Dropdown';

  import {
    track,
    trackingEvents
  } from '../../store/index.js';

  import Reasons from './_Reasons.svelte';

  export let targetElem;
  export let complete = () => { };
  export let cancel = () => { };
  export let isWaiting = false;

  function onModalOk() {
    complete({
      name,
      number,
      expiry,
      cvc,
      cardType
    });
  }

  function handleReason(event) {
    const {reason, isOther}  = event.detail;
    // track('ITINERARY_HOTEL_PROMPT_ACTIONED', reason, isOther);
    complete({bookHotel: false, reason, isOther});
  }

  export function handleHotel() {
    complete({bookHotel: true});
  }

  function getMenuContainer() {
    return document.getElementById('reasonsContainer');
  }
</script>

<style>
  .intro {
    margin: 0 0 60px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }

  .first {
    margin: 0 5px 0 0;
    position: relative;
  }
</style>



<Modal okText="Save" title="Do you need a hotel?" hasFooter="{false}" isClosable="{false}" isOverlayClosable="{false}">

  <p class="intro">
    It appears you didn't select a hotel although your booking spans multiple days.
    If you don't need a hotel please select the reason why below.
  </p>

  <div class="footer">
    <div class="first" id="reasonsContainer">
      <Dropdown MenuComponent="{Reasons}" on:select="{handleReason}" {getMenuContainer}>
        <Button type="{buttonOptions.type.SUBTLE}">I don't need a hotel</Button>
      </Dropdown>
    </div>
    
    <Button on:click="{handleHotel}">Yes, I need a hotel</Button>
  </div>
</Modal>
