<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  import {
    _DropdownMenu as DropdownMenu,
    _DropdownMenuItem as DropdownMenuItem,
    _DropdownMenuDivider as DropdownMenuDivider
  } from '@ctm/ui/Dropdown';
  
  import Button, { options as buttonOptions } from '@ctm/ui/Button';

  let reasons = shuffleArray([
    'Staying with friends or family',
    'No suitable hotel available in the system',
    'Staying onsite',
    'Third party accommodation already provided',
    'Hotel booked separately through CTM'
  ])

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  }

  function onClick(reason) {
    dispatch('select', { reason, isOther: false });
  }

  function handleTextClick(event) {
    event.stopPropagation();
  }

  function handleSave() {
    dispatch('select', { reason: customReason, isOther: true });
  }

  let customReason = '';
  $: customReasonTrimmed = customReason.trim();
  $: customReasonCount = customReasonTrimmed.length;

  $: {
    if (customReason.length > 40) {
      customReason = customReason.substring(0, 40);
    }
  }
</script>

<style>
  .container {
    width: 420px;
  }

  .free {
    padding: 10px 16px;

  }

  .free label {
    position: relative;
    display: block;
    margin: 0 0 4px;
    color: #253646;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.13px;
    line-height: 19px;
  }

  .free label span {
    font-size: 12px;
    position: absolute;
    right: 0;
  }

  .customReason {
    height: 44px;
    border: 1px solid #D8DBDF;
    border-radius: 3px;
    background-color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 0 16px;
    margin: 0 0 3px;
  }

  .cta {
    display: flex;
    justify-content: flex-end;
  }

  .chars {
    font-size: 12px;
    margin: 0 0 12px;
    color: #999;
    display: block;
    text-align: right;
  }

</style>

<div class="container">
  <DropdownMenu>
    {#each reasons as reason}
      <DropdownMenuItem on:click="{ () => onClick(reason) }">{reason}</DropdownMenuItem>
    {/each}

    <DropdownMenuDivider />

    <div class="free" on:click="{handleTextClick}">
      <label>Other reason <span>{customReasonCount}/40</span></label>
      <input class="customReason" bind:value="{customReason}" placeholder="{ 'Describe reason' }">
      <span class="chars">Minimum 3 characters</span>
      <div class="cta">
        <Button on:click="{handleSave}" type="{buttonOptions.type.PRIMARY}" isDisabled={customReasonCount < 3}>Save and Continue</Button>
      </div>
    </div>

  </DropdownMenu>
</div>
